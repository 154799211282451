import logoLight from "@/images/logo.png";
import logoDark from "@/images/logo2.png";
import logoRdevit from "@/images/RV-Web-logo.png";
import TN from "@/images/flag-round-250-tn.png";

/* export const LogoImage = {
  light: logoLight,
  dark: logoDark,
}; */
export const LogoImage = {
  light: logoRdevit,
  dark: logoRdevit,
};
export const TunisiaLogo = {
  TN
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    /* subItems: [
     {
        name: "Home 01",
        url: "/",
      } ,
      {
        name: "Home 02",
        url: "/index-2",
      },
      {
        name: "Home 03",
        url: "/index-3",
      }, 
    ],*/
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/service",
    subItems: [
      {
        name: "WEBSITE DEVELOPMENT",
        url: "/website-development",
      },
      {
        name: "MOBILE DEVELOPMENT",
        url: "/mobile-development",
      },
      {
        name: "UX/UI APP DESIGN",
        url: "/uxui-design",
      },
      {
        name: "DIGITAL MARKETING",
        url: "/digital-marketing",
      },
    ],
  },
  /* {
    name: "Portfolio",
    url: "/portfolio",
    subItems: [
      {
        name: "Portfolio 01",
        url: "/portfolio",
      },
      {
        name: "Portfolio Details",
        url: "/portfolio-details",
      },
    ],
  }, */
 /*  {
    name: "CAREERS",
    url: "/blog",
    subItems: [
      {
        name: "JOINS US (JOBS)",
        url: "/blog",
      },
      {
        name: "TRAINEESHIP ( STAGES PFE )",
        url: "/blog-2",
      },
      {
        name: "FREELANCE JOBS",
        url: "/blog-3",
      }
    ],
  }, */
  {
    name: "Contact",
    url: "/contact",
  },
];

import sliderOne01 from "@/images/slider/homyhomy66.png";
import sliderOne02 from "@/images/slider/Redevit-home-two.png";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "WELCOME TO REDEVIT DIGITAL SERVICES",
   /*  title: "Helping companies succeed \n Creating real wealth.", */
    title: "We deliver technology\n with passion",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "WELCOME TO REDEVIT DIGITAL SERVICES",
    title: "Smart Solutions For Revolution", 
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

import sliderTwo01 from "@/images/slider/2_1.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg";

export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderTwo03,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "DIGITAL\nMARKETING\nAGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];
/* import sliderTwo01 from "@/images/slider/2_1.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg"; */
import web2 from "@/images/web-22.png"; 
import web1 from "@/images/website-dev-home-service-redevit.png"; 
import mobile1 from "@/images/mobile-dev5.png"; 
import mobile2 from "@/images/mobile-2.png"; 
import digitalmarketing1 from "@/images/digitalmarketing1.png"; 
import digitalmarketing222 from "@/images/digitalmarketing222.png"; 
import uxui3 from "@/images/uiux-design-finale11.png"; 
import uxui22 from "@/images/uiux22desin.png"; 

export const ServicesDetails = [
  {
    serviceTitle: "WEBSITE DEVELOPMENT",
    serviceContent: "Be it custom Web applications development and SaaS platforms, ecommerce website development or informative lead generation sites, our value proposition, when it comes to web development, is to build solutions by prioritizing the context and needs of the end-users, ensuring meaningful results. Enterprise or small business web development, we strive to deliver remarkable results. \n\nOur team of passionate & skilled engineers have vast experience in building web-based products using the latest technology stacks. We not only develop, but we also validate our solutions by testing in various environments to ensure nothing breaks in production.",
    serviceImage: web1,
    sectionTitle: "Our Services",
    sectionContent: "",
    sectionImage: web2,
    lists: [
      {
        item: "RESPONSIVE WEB DESIGN",
      },
      {
        item: "CLOUD BASED APPLICATION",
      },
      {
        item: "SAAS APPLICATIONS",
      },
      {
        item: "SINGLE-PAGE APPS",
      },
      {
        item: "API DEVELOPMENT AND DOCUMENTATION",
      },
      {
        item: "BUSINESS AUTOMATION",
      },
      {
        item: "QUALITY ASSURANCE & TESTING",
      }
 
    ],
    paragraphe1 :"\nYour website is your digital headquarters, where the primary goal is to attract not only traffic but also generate lead generations. Most of the businesses ignore the importance of well-operating web-forum, which harms your brand reputation. Your social media platforms may help you convert traffic to your website, but a poorly designed web user interface causes you to lose a customer's trust. We believe in providing our clients with premium Website Design and Development Replace with Website Design & Development Services. Our developed web pages work like a charm - user-friendly interface combined with the design aesthetics makes our work stand out.",
    paragraphe2 :"Our web solutions are built using the latest web technologies and they are secure, robust and scalable which help businesses to reduce overall operational cost and enhance operational efficiency.",

    sidebarTitle: "WEBSITE DEVELOPMENT",
    sidebarContent: "We deliver the best web development services to craft bespoke websites, web applications, web-tools, etc. to global businesses and startups with 100% project delivery.",
    SidebarFooter :"REDEVIT DIGITAL SERVICES"
    
  },
  {
    serviceTitle: "UX/UI APP DESIGN",
    serviceContent: "We'd build and design you applications that will influence people and erase your competitor's competitiveness. We design a simple, user-friendly interface that flawlessly runs and gets the job done. Our UX/UI Design combines iterative thinking, data-driven problem solving, and a big-picture vision to get to the core of what users need. \n\n To build solutions that drive engagement and growth, we pursue an iterative process that uses divergent and convergent thinking throughout research and development. We design and prototype solutions, testing them with end users to evaluate their success and determine next steps. Every small interaction, from animations to microcopy, is designed to delight the end users. This process optimizes the design to address user needs and transform their digital journey.",
    serviceImage: uxui3,
    sectionTitle: "UX/UI DESIGN PROCESS",
    sectionContent: "",
    sectionImage: uxui22, 
    lists: [
      {
        item: "Strategy Workshop",
      },
      {
        item: "Defining User Persona & Empathy Mapping",
      },
      {
        item: "Analyzing Competitor Landscape",
      },
      {
        item: "Architecting Information",
      },
      {
        item: "Sketches & Wireframing",
      },
      {
        item: "Defining UI Guidelines",
      }
 
    ],
    paragraphe1 :"\nDesign development at REDEVIT DIGITAL SERVICES is not about a separate stage of development. It is closely integrated with business analysis and definition of requirements. Business Analyst processes information for UX/UI designers. The designer starts the work by understanding the solution and cooperating with BA who defines marketability, helps in achieving goals with the design.",
    paragraphe2 :"The first thing a client has in the hand is a visual picture of what he wants. Our design services are done carefully, our UI/UX Designers commit to project success, each step is negotiated with the client. We receive directions from clients, we hear their requirements and deliver solutions that would suit the identity of the owner, and convey the concept hidden behind.",

    sidebarTitle: "UX/UI DESIGN",
    sidebarContent: "As an experienced user experience design company, we enroot some ideas behind making the product original, exciting. We aim to create the feeling of newness but preserve high quality and trust for long-term relations with your potential client.",
    SidebarFooter :"REDEVIT DIGITAL SERVICES"
  },
  {
    serviceTitle: "DIGITAL MARKETING",
    serviceContent: "We work to analyze the behaviors of target customers, using key market insights to evaluate potential opportunities for each persona. In doing so, we discover the best ways for you to interact with your customers, from acquisition to retention.\nWe leverage the power of data to accelerate marketing activities, improve efficiency, and increase ROI.",
    serviceImage: digitalmarketing1,
    sectionTitle: "Our Advantages",
    sectionContent: "",
    sectionImage: digitalmarketing222,
    lists: [
      {
        item: "Media Performance",
      },
      {
        item: "Brand Performance",
      },
      {
        item: "Engagement & Loyalty",
      },
      {
        item: "Digital Media & Communications",
      },
      {
        item: "Digital Visibility",
      },
      {
        item: "Data Insights",
      },
      {
        item: "Customer Lifecycle",
      },
    ],
    paragraphe1 :"\nConsumers of today want brands they can trust, companies that know them, communications that are personalized and relevant, and offers tailored to their needs and preferences. For you to deliver on their expectations, you need to incorporate digital marketing into your business strategy. You need to understand your customers, monitor what is being viewed, for how often and for how long, and to analyze marketing campaigns, sales conversions, and understand what is working and what is not – and quickly.",
    paragraphe2 :"Our digital marketing solutions focus on strategy, data science, media, and advertising and leverage data intelligence to boost business, connect people, and create experiences. Using our expertise, best practices, and statistical techniques, we produce actionable business insights based on data mining and predictive models. Our insights are further solidified through research and analytics.\n\n Let's reimagine your marketing strategy and ensure your business thrives with better interactions, engagement, loyalty, and brand awareness.",

    sidebarTitle: "DIGITAL MARKETING SOLUTIONS",
    sidebarContent: "When marketing and technology collide, cutting-edge ideas emerge. Connect with your audience at the right time by leveraging nerd-tested, creative-approved solutions backed by data science, technology, and strategy.    ",
    SidebarFooter :"REDEVIT DIGITAL SERVICES"
  },
  {
    serviceTitle: "MOBILE DEVELOPMENT",
    serviceContent: "Performance- one of the biggest predictors of app success - we take it damn seriously. To ensure your idea doesn’t turn into a horror story, we foolproof our mobile app development process. By reducing the app’s response lag, eliminating data redundancy, optimizing memory allocation and implementing high-end data encryption- the result you get is a stunning mobile app experience. And it is evident from the raking user base in App Store as well as Google Play Store for our clients.",
    serviceImage: mobile1,
    sectionTitle: "OUR Best practices",
    /* sectionContent: "WELCOME TO MEIPALY AGENCY", */
    sectionImage: mobile2,
    lists: [
      {
        item: "Attention to Memory Limitations",
      },
      {
        item: "Attention to Security",
      },
      {
        item: "Optimized Battery Consumption",
      },
      {
        item: "Version Support",
      },
      {
        item: "Screen Variance & Compatibility",
      },
      {
        item: "High & Quality Performance",
      },
      {
        item: "Offline Mode Importance",
      },
 
    ],
    paragraphe1 :"\nWe ship rich experiences to the small screens, which translates into the success of your business, ease of operations and engagement of the consumers with our exceptionally deep understanding of user experience and mobile technology. We work with multiple ecosystems in order to create maximum impact to your end-users.",
    paragraphe2 :"What We Develop & Deliver : \n\nCustom applications that work perfectly on the entire iOS platform including the latest and the most recent iOS versions and iOS devices like iPhones, iPads, Apple Watches, Apple TV.\n\nBespoke Android mobile app solutions for global brands, enterprises and startups. Covering the entire spectrum on the android platform, we can help you craft niche Android apps for Smartphones, Tablets, Android Wear and Android TV. \n\n  Cross-platform mobile app development  that empowers businesses with a wide array of mobile applications. Our cross-platform mobile apps empower clients with multiple benefits and also help to cover a wider audience using devices with different OS. Our cross-platform developers have proven expertise in building affordable, quality and versatile mobile apps with different cross-platform technologies and frameworks. \n\nHybrid applications combine the best of both worlds, web and native applications. Apps built through our top hybrid mobile app development services reduce the development cost, provide easy access to device data, work offline and scale to a variety of platforms and OS.",
    

    sidebarTitle: "MOBILE APP DEVELOPMENT",
    sidebarContent: "We leverage the mobile device capabilities to create a memorable end-user experience to spur your brand’s growth. A trusted mobile app development company for world-class brands.",
    SidebarFooter :"REDEVIT DIGITAL SERVICES"
  },
];

import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "contact@redevit.tn",
      },
      {
        name: "+216 99 247 867",
      },
    ],
  },
  {
    title: " Tunisia",
    infos: [
      {
        name: "20 Mars Jawhara, Sousse - 4000",
      },
      {
        name: "conatact@redevit.tn",
      },
      {
        name: "+216 99 247 867",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "+216 99 247 867",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact with us",
  title: "write us a message",
 /*  description:
    "We are committed to providing our customers with exceptional service while \n      offering our employees the best training. ", */
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";
import portfolio05 from "@/images/portfolio/5.jpg";
import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    " Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ",
  client: "Jessica Brown",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Modren Design",
    text:
      "Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Digital products",
    text:
      "Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Marketing Strategy",
    text:
      "Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
  {
    title: "Marketing Strategy",
    text:
      "Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  }
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to smart meipaly web agency",
  title: "We develop digital solutions that \n help grow businesses.",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "We develop digital solutions that \n help grow businesses",
    subTitle: "Services we are offering",
    text:
      "We offer a range of services to help your business become more efficient and agile\n in today's connected and digital world",
  },
  posts: [
    {
      title: "WEBSITE DEVELOPMENT",
      iconName: "mei-web-design",
      url: "/website-development",
    },
    {
      title: "MOBILE DEVELOPMENT",
      iconName: "mei-app-development",
      url: "/mobile-development",
    },
    {
      title: "UX/UI APP DESIGN",
      iconName: "mei-computer-graphic",
      url: "/uxui-design",
    },
    {
      title: "DIGITAL MARKETING",
      iconName: "mei-development-1",
      url: "/digital-marketing",
    }
 /*    {
      title: "SEo & Content Writing",
      iconName: "mei-development",
      url: "/service-details",
    }, */

  ],
};

import serviceweb from "@/images/home_1/serviceweb.png";
import serviceuxui from "@/images/home_1/uxuiservice.png";
import digitalservice from "@/images/home_1/digitalservice.png";
import mobiledevservice from "@/images/home_1/mobiledevservice.png";
import serviceOne03 from "@/images/home_1/8.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Let’s create something",
    subTitle: "what we do",
    text:
      "We build digital products that create a positive impact\nIf you have a vision to make a difference in the world, then we have a way to get you there",
  },
  posts: [
    {
      title: "WEBSITE DEVELOPMENT",
      image: serviceweb,
      url: "/website-development",
    },
    {
      title: "MOBILE DEVELOPMENT",
      image: mobiledevservice,
      url: "/mobile-development",
    },
    {
      title: "UX/UI APP DESIGN",
      image: serviceuxui,
      url: "/uxui-design",
    },
    {
      title: "DIGITAL MARKETING",
      image: digitalservice,
      url: "/digital-marketing",
    }

  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

import aboutOne01 from "@/images/about/about-second.png";
import aboutOne02 from "@/images/about/2.jpg";

export const AboutOneData = {
  sectionContent: {
    title: "build better digital solutions alot quicker with REDEVIT DIGITAL SERVICES",
    subTitle: "get to know us",
    content: "We assembled an international team of business and technology experts who work closely with customers to understand their needs and business imperatives. We know that our team is our most valuable asset so we constantly look for exceptional, talented, dedicated, and professional people who want to be part of our team, and contribute to our growing company.\n\n We are successful because we have pioneered technology solutions to solve real-world, business challenges.",
  },
  gallery: [aboutOne01/* , aboutOne02 */],
   counter: {
    title: ""/* "Company Started" */,
    number: '' /* 1990 */
  }, 
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title:  "expert people",
    subTitle:  "meet the team" ,
    text:
       "We are committed to providing our customers with exceptional service \n while offering our employees the best training." ,
  },
  posts: [
     {
      image: team01,
      name: "Herman Fisher",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Charlie Kennedy",
      designation: "Brand Designer",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Helena Adkins",
      designation: "Seinor Designer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Virginia Farmer",
      designation: "Marketing Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Philip Hansen",
      designation: "Co Founder",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    }, 
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
     title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.", 
  },
  video: {
     image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video", 
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

import testimonial01 from "@/images/home_1/t1.jpg";
import testimonial02 from "@/images/home_1/t2.jpg";
import testimonial03 from "@/images/home_1/t3.jpg";

export const TestimonialsOneData = {
/*   sectionContent: {
    subTitle: "our testimonials",
    title: "happy customers",
  }, */
  posts: /* [ */
    /* {
      name: "Cecilia Colon",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Chase Hanson",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Mittie Flores",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Hunter Brewer",
      designation: "Manager",
      image: testimonial03,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },
    {
      name: "Anne Stone",
      designation: "CO Founder",
      image: testimonial02,
      content:
        "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
      date: "25 nov, 2018",
    },*/
    {
     /*  name: "Hunter Brewer",
      designation: "Ma nager",
      image: testimonial03, */
      content:
      "REDEVIT is a full-stack, multi-disciplinary custom digital solutions development company, we rely on our profound expertise, technology vision and streamlined processes to provide end-to-end custom digital solutions and turn it technological into growth enablers. Our team is composed of technology-savvy professionals who share your business culture and take full responsibility for custom business application development, supporting you from early ideation and solution discovery to quality control and ongoing delivery of new features and strategies."
      /* date: "25 nov, 2018", */
    },
  /* ], */
};

import latestech from "@/images/home_1/latestech.png";
import awesomesupport from "@/images/home_1/awesome-support-ready.png";
import reliabilty from "@/images/home_1/reliabilty.png";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "We transform businesses with powerful and adaptable digital solutions that satisfy the needs of today \n and unlock the opportunities of tomorrow",
    },
  posts: [
    {
      title: "Latest technology",
      content:
        "The development of reliable and scalable software solutions for any OS, browser and device.\n We bring together deep industry expertise and the latest IT advancements to deliver custom solutions and products that perfectly fit the needs and behavior of their users. ",
      image: latestech,
      lists: [
        {
          item: "Progressive Web Apps",
        },
        {
          item: "Big Data",
        },
        {
          item: "Virtual Reality, Augmented Reality and Mixed Reality",
        },
        
        {
          item: "Artificial Intelligence and Machine Learning",
        },
        {
          item: "Computer Vision",
        },
        {
          item: "Blockchain",
        },
        {
          item: "Cyber Security",
        },
   
      ],
    },
    {
      title: "Awesome Support",
      content:
        "Whatever your specialty and the size of your business, technology is essential to continued success.\n   While most businesses know they need to embrace these tools, they don't know which tools best fit their business.\n\n REDEVIT DIGITAL SERVICES is a partner for your digital journey. We provide the strategy, experience, and technical expertise to help you thrive in the new dynamic world. We know how automation, data analytics, market intelligence and cognitive computing can help your business learn and grow, as well as how these same tools will allow you to keep up with the demands of customers.\n\n We incorporate new technology into your business to modernize and transform it from end-to-end. With REDEVIT DIGITAL SERVICES, you'll know your business is designed for tomorrow, not just today.",
      image: awesomesupport,
     lists: [
          /*{
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        },*/
      ], 
    },
     {
      title: "Reliability",
      content: "We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards. We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. We offer true and reciprocal communication and execute the same service as promised to our customers.\n\nOur safe, secure and reliable solutions provide an exceptional experience for our every customer. \n\nBy putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client.\n\nOur commitment is to deliver outstanding cutting edge IT solutions that add real value.",
      image: reliabilty,
      lists: [
       /*  {
          item: "Self-contained, state-of-the-art time clock",
        },
        {
          item: "Scalability of up to 500 employees per time clock",
        },
        {
          item: "The ability to connect up to 32 time clocks",
        },
        {
          item: "Employee self-enrollment",
        },
        {
          item: "Payroll integration",
        },
        {
          item: "Built-in backup camera to verify failed punches",
        }, */
      ],
    }, 
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Great things in business are never done by one person.",
  specialText: "They’re done by a team of people.",
  text:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "work showcase",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "Digital Experience",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

import aboutTwo01 from "@/images/home_1/homy-about-4.png";
import aboutTwo02 from "@/images/home_1/1.png";

export const AboutTwoData = {
  sectionContent: {
    title: "We Provide The World's Best Digital Solutions",
    subTitle: "welcom to REDEVIT DIGITAL SERVICES",
    text:
      "Digital solutions have made it possible for businesses to rethink the way they can tackle problems in simple, efficient, innovative and scalable ways. These digital solutions and technologies can work together or apart, giving you the freedom to pick and mix the right solutions to suit your needs. And sometimes, the simplest solution can be the most effective.\n\n We will work with you in a stepped approach and within the confines of your business structure and budget. We will work with you to identify your core problems, and propose the best digital technologies and tools to resolve them.",
  },
  button: {
    label: "Learn More",
    url: "/about",
  },
  gallery: [aboutTwo01/* , aboutTwo02 */],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];
